<template>
  <div>
    <b-card class="rounded-lg">
      <b-card-text class="items-center text-center">
        <div class="items-center">
          <h2>
            {{ $t('FaqCard.title') }}
          </h2>

          <div class="d-flex justify-content-center">
            <p class="text-center font-small-3 mb-0" style="width: 33%">
              {{ $t('FaqCard.subtitle') }}
            </p>
          </div>
        </div>
        <div class="flex justify-end">
          <div class="w-1/6">
            <b-form-input
                size="sm"
                @input="searchInputTrigger"
                placeholder="Sök"
            />
          </div>
        </div>
      </b-card-text>
      <b-card-text class="">
        <h2>{{ $t('FaqCard.categories') }}</h2>
        <b-card no-body>
          <Loading v-if="categories.length == 0"/>
          <b-tabs v-else pills card>
            <b-tab
                v-for="(data, index) in categories"
                :key="index"
                :title="data[categoriesLang]"
                @click="tabsAction(data)"
            >
              <b-card-text>
                <div class="accordion" role="tablist">
                  <Loading v-if="faqLoading"/>
                  <template v-else>
                                        <span v-if="currentFaq.length > 0">
                                            <b-card
                                                no-body
                                                class="mb-1 border rounded-lg"
                                                v-for="(
                                                    data, index
                                                ) in currentFaq"
                                                :key="index"
                                            >
                                                <b-card-header
                                                    header-tag="header"
                                                    class="overide-pad"
                                                    role="tab"
                                                >
                                                    <b-button
                                                        block
                                                        v-b-toggle="
                                                            'accordion-productdetails' +
                                                            index
                                                        "
                                                        variant="transparent"
                                                        class="px-1"
                                                        @click="
                                                            data.openTabs =
                                                                !data.openTabs
                                                        "
                                                    >
                                                        <div
                                                            class="d-flex justify-content-between"
                                                        >
                                                            <p
                                                                class="mb-0 text-gray-800"
                                                                v-text="
                                                                    data[
                                                                        questionLang
                                                                    ]
                                                                "
                                                            />

                                                            <feather-icon
                                                                size="20"
                                                                :icon="
                                                                    data.openTabs
                                                                        ? 'MinusIcon'
                                                                        : 'PlusIcon'
                                                                "
                                                            />
                                                        </div>
                                                    </b-button>
                                                </b-card-header>
                                                <b-collapse
                                                    :id="
                                                        'accordion-productdetails' +
                                                        index
                                                    "
                                                    :visible="index === 0"
                                                    :accordion="
                                                        'productdetails-accordion' +
                                                        index
                                                    "
                                                    role="tabpanel"
                                                >
                                                    <b-card-body
                                                        class="pt-0"
                                                        style="
                                                            padding-left: 17px;
                                                        "
                                                    >
                                                        <b-card-text
                                                            class="text-sm"
                                                            v-text="
                                                                data[answerLang]
                                                            "
                                                        >
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </span>

                    <!-- <b-card v-else class="mb-1 rounded-lg">
                        <Loading />
                    </b-card> -->
                  </template>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import debounce from 'lodash.debounce'

export default {
  data() {
    return {
      faqList: [],
      categories: [],
      currentFaq: [],
      faqLoading: true,
      config: useAppConfig(),
      currentCategory: {}
    };
  },

  computed: {
    answerLang() {
      if (this.getLang == '') {
        return this.swedishLang ? 'swe_answer' : 'eng_answer';
      } else return this.getLang == 'sv' ? 'swe_answer' : 'eng_answer';
    },
    questionLang() {
      if (this.getLang == '') {
        return this.swedishLang ? 'swe_question' : 'eng_question';
      } else
        return this.getLang == 'sv' ? 'swe_question' : 'eng_question';
    },
    categoriesLang() {
      if (this.getLang == '') {
        return this.swedishLang ? 'swe_category' : 'eng_category';
      } else
        return this.getLang == 'sv' ? 'swe_category' : 'eng_category';
    },
    getLang() {
      return this.config.lang.value;
    },
  },

  methods: {
    searchInputTrigger: debounce(function (value) {
      if (value !== '') {
        this.faqLoading = true;
        let params = `?search=${value}`
        if ('swe_category' in this.currentCategory) {
          params += `&category__swe_category=${this.currentCategory.swe_category}`
        }
        this.$useJwt.faqList(params).then(res => {
          const {results} = res.data;
          this.currentFaq = results;
          this.faqList = results

          this.faqLoading = false;
        });
      } else {
        this.tabsAction(this.currentCategory)
      }

    }, 500),
    tabsAction(value) {
      this.faqLoading = false;
      this.currentCategory = value
      let params = `?category__swe_category=${value.swe_category}`
      this.$useJwt.faqList(params).then(res => {
        const {results} = res.data;
        this.currentFaq = results;
        this.faqList = results

        this.faqLoading = false;
      }).catch(er => {
        this.faqLoading = false;
      });
    },
    getFaqs() {
      let params = ''
      this.$useJwt.faqList(params).then(res => {
        const {results} = res.data;
        this.currentFaq = results;
        this.faqList = results


        this.faqLoading = false;
      });
    },
    init(params = null) {
      this.$useJwt.faqCategories().then(res => {
        const {results} = res.data;
        this.categories = results;
      });
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.init();
    this.getFaqs()
  },
};
</script>

<style scoped>
.overide-pad {
  padding: unset !important;
}
</style>
