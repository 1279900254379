var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"rounded-lg"},[_c('b-card-text',{staticClass:"items-center text-center"},[_c('div',{staticClass:"items-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('FaqCard.title'))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"text-center font-small-3 mb-0",staticStyle:{"width":"33%"}},[_vm._v(" "+_vm._s(_vm.$t('FaqCard.subtitle'))+" ")])])]),_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"w-1/6"},[_c('b-form-input',{attrs:{"size":"sm","placeholder":"Sök"},on:{"input":_vm.searchInputTrigger}})],1)])]),_c('b-card-text',{},[_c('h2',[_vm._v(_vm._s(_vm.$t('FaqCard.categories')))]),_c('b-card',{attrs:{"no-body":""}},[(_vm.categories.length == 0)?_c('Loading'):_c('b-tabs',{attrs:{"pills":"","card":""}},_vm._l((_vm.categories),function(data,index){return _c('b-tab',{key:index,attrs:{"title":data[_vm.categoriesLang]},on:{"click":function($event){return _vm.tabsAction(data)}}},[_c('b-card-text',[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[(_vm.faqLoading)?_c('Loading'):[(_vm.currentFaq.length > 0)?_c('span',_vm._l((_vm.currentFaq),function(data,index){return _c('b-card',{key:index,staticClass:"mb-1 border rounded-lg",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"overide-pad",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                                          'accordion-productdetails' +
                                                          index
                                                      ),expression:"\n                                                          'accordion-productdetails' +\n                                                          index\n                                                      "}],staticClass:"px-1",attrs:{"block":"","variant":"transparent"},on:{"click":function($event){data.openTabs =
                                                              !data.openTabs}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0 text-gray-800",domProps:{"textContent":_vm._s(
                                                                  data[
                                                                      _vm.questionLang
                                                                  ]
                                                              )}}),_c('feather-icon',{attrs:{"size":"20","icon":data.openTabs
                                                                      ? 'MinusIcon'
                                                                      : 'PlusIcon'}})],1)])],1),_c('b-collapse',{attrs:{"id":'accordion-productdetails' +
                                                      index,"visible":index === 0,"accordion":'productdetails-accordion' +
                                                      index,"role":"tabpanel"}},[_c('b-card-body',{staticClass:"pt-0",staticStyle:{"padding-left":"17px"}},[_c('b-card-text',{staticClass:"text-sm",domProps:{"textContent":_vm._s(
                                                              data[_vm.answerLang]
                                                          )}})],1)],1)],1)}),1):_vm._e()]],2)])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }